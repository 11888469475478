import {Component, OnInit} from '@angular/core';
import {LoginService} from '../services/login.service';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

    lottieConfig: any;

    constructor(public loginService: LoginService) {

        this.lottieConfig = {
            path: 'assets/loader.json',
            renderer: 'canvas',
            autoplay: true,
            loop: true
        };

        // this.loginService.inAuthListener();

    }


    ngOnInit() {
    }

}

import {NgModule} from '@angular/core';
import {CommonModule,} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';

import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {LandingPageComponent} from './landing-page/landing-page.component';

const routes: Routes = [

    {
        path: '',
        component: LandingPageComponent,
        pathMatch: 'full',
    },

    {
        path: 'app',
        pathMatch: 'prefix',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
            }
        ]
    }, {
        path: 'auth',
        pathMatch: 'prefix',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './layouts/auth-layout/auth-layout.module#AuthLayoutModule'
            }
        ]
    }, {
        path: '**',
        redirectTo: 'dashboard'
    }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {
    useHash: true,
    relativeLinkResolution: 'legacy'
})
    ],
    exports: [],
})
export class AppRoutingModule {
}

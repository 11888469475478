import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { KeySecret } from "../beans/KeySecret";
import { MatDialog } from "@angular/material/dialog";
import { LoadingDialogComponent } from "../dialogs/loading-dialog/loading-dialog.component";
import { ToastrService } from "ngx-toastr";
import { LocalConstants } from "../beans/LocalConstants";
import { LogsService } from "./logs.service";
import * as firebase from "firebase";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  isAuthenticated = true;
  uid;
  keySecret: KeySecret = new KeySecret();
  refreshing = false;

  constructor(
    public _auth: AngularFireAuth,
    private router: Router,
    public db: AngularFirestore,
    public dialog: MatDialog,
    public toastService: ToastrService,
    public logsService: LogsService
  ) {
    this.inAuthListener();
  }

  inAuthListener() {
    // console.log('in Auth');

    // const res = this._auth.auth.currentUser;
    // console.log(res);
    // if (res && res.uid) {
    //     console.log('user is logged in');
    //
    //     this.navigateToDashboard(res);
    //     // console.log('UID is ' + this.uid);
    //     // console.log(this.router.url);
    //
    //
    //     return true;
    // } else {
    //     this.isAuthenticated = false;
    //     this.uid = undefined;
    //
    //     console.log('user not logged in');
    //     this.router.navigate(['/auth/login']);
    //     return false;
    // }

    return this._auth.authState.subscribe((res) => {
      if (res && res.uid) {
        // console.log('user is logged in');

        this.navigateToDashboard(res);

        // if (this.router.url === '/login') {
        //     this.router.navigate(['/']);
        // }

        return true;
      } else {
        // console.log('user not logged in');

        if (
          !(
            this.router.url === "/auth/login" ||
            this.router.url === "/auth/register"
          )
        ) {
          this.router.navigate(["/auth/login"]);
        }

        return false;
      }
    });

    // this._auth.auth.onAuthStateChanged(result => {
    //     // console.log('Result' + result);
    //     if (result == null || result.uid == null) {
    //         this.router.navigate(['/login']);
    //
    //     } else {
    //
    //         this.isAuthenticated = true;
    //
    //         this.uid = result.uid;
    //
    //         this.getCurrentuser();
    //
    //         console.log('UID is ' + this.uid);
    //
    //         this.router.navigate(['']);
    //
    //     }
    //
    // })
  }

  logout() {
    this._auth
      .signOut()
      .then((result) => {
        // this.router.navigate(['/auth/login']);
      })
      .catch((error) => {
        this.toastService.error(error, "Error");
        // console.log('Error' + error);
      });
  }

  saveDynamicLink(link: string) {
    return this.db
      .doc<KeySecret>(LocalConstants.keysCollection + "/" + this.uid)
      .update({ link: link })
      .then((result) => {
        // console.log('updated');
        this.keySecret.link = link;
      })
      .catch((error) => {
        // console.log(error);
        this.toastService.error(error, "Error");
      });
  }

  getCurrentuser(navigate: boolean) {
    if (this.refreshing) {
      return;
    }

    this.refreshing = true;

    const dialogRef = this.dialog.open(LoadingDialogComponent, {
      panelClass: "my-panel",
    });

    this.db
      .doc<KeySecret>(LocalConstants.keysCollection + "/" + this.uid)
      .get()
      .toPromise()
      .then((doc) => {
        dialogRef.close();
        this.refreshing = false;
        if (!doc.exists) {
          this.logout();
          // console.log('Key Secret does not exist');
          return;
        } else {
          // console.log(doc.data());
          this.keySecret = doc.data() as KeySecret;
          if (this.keySecret !== undefined) {
            this.keySecret.freeUsages = 50 - this.keySecret.usages;
            if (this.keySecret.freeUsages < 0) {
              this.keySecret.freeUsages = 0;
            }

            if (this.keySecret.lastOrder) {
              // console.log(parseInt(this.keySecret.lastOrder, 0));
              this.keySecret.lastOrderDate = new Date(
                parseInt(this.keySecret.lastOrder, 0)
              );
              // console.log(this.keySecret.lastOrderDate);
            }
            if (this.keySecret.lastUsage) {
              // @ts-ignore
              this.keySecret.lastUsage = this.keySecret.lastUsage.toDate();
            }

            if (this.keySecret.creationDate) {
              // @ts-ignore
              this.keySecret.creationDate =this.keySecret.creationDate.toDate();
            }

            if (navigate) {
              if (!this.router.url.includes("/app/")) {
                this.router.navigate(["/app/dashboard"]);
              }
            }

            this.updateLastSeen();
          }
          // console.log(this.keySecret);
        }
      })
      .catch((error) => {
        // console.log('Error ' + error);
      });
  }

  private updateLastSeen() {
    this.db
      .doc(LocalConstants.keysCollection + "/" + this.uid)
      .update({
        lastSeen: firebase.default.firestore.FieldValue.serverTimestamp(),
      })
      .then((result) => {
        // console.log('updated');
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  validateEmail(input) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(input).toLowerCase());

    // console.log(input);
    // var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    // if (input.match(validRegex)) {
    //     console.log(true);
    //   return true;

    // } else {
    //     console.log(false);
    //   return false;

    // }
  }

  updateProfile(
    companyName: string,
    name: string,
    gstNumber: string,
    email: string,
    role: string
  ) {
    companyName = companyName.trim();
    name = name.trim();
    email = email.trim();
    role = role.trim();
    gstNumber = gstNumber.trim().toUpperCase();

    if (companyName.length == 0) {
      this.toastService.error("Company Name Required", "Error");
      return;
    }

    if (name.length == 0) {
      this.toastService.error("Name Required", "Error");
      return;
    }

    if (gstNumber.length > 0 && gstNumber.length != 15) {
      this.toastService.error("Valid GST Number Required", "Error");
      return;
    }
    if (email.length == 0 || !this.validateEmail(email)) {
      this.toastService.error("Valid Email Required", "Error");
      return;
    }

    const dialogRef = this.dialog.open(LoadingDialogComponent, {
      panelClass: "my-panel",
    });
    return this.db
      .doc<KeySecret>(LocalConstants.keysCollection + "/" + this.uid)
      .update({
        companyName: companyName,
        name: name,
        gstNumber: gstNumber.length == 0 ? null : gstNumber,
        email: email,
        role: role,
      })
      .then((result) => {
        dialogRef.close();
        // console.log('updated');
        this.keySecret.companyName = companyName;
        this.keySecret.name = name;
        this.keySecret.gstNumber = gstNumber;
        this.keySecret.email = email;
        this.keySecret.role = role;
        this.toastService.success("Profile updated", "Success");
      })
      .catch((error) => {
        dialogRef.close();
        // console.log(error);
        this.toastService.error(error, "Error");
      });
  }

  updateMinLimit(value: number) {
    const dialogRef = this.dialog.open(LoadingDialogComponent, {
      panelClass: "my-panel",
    });
    this.db
      .doc<KeySecret>(LocalConstants.keysCollection + "/" + this.uid)
      .update({ minLimit: value })
      .then((result) => {
        dialogRef.close();
        // console.log('updated');
        this.keySecret.minLimit = value;
        this.toastService.success("Min Alert Activated", "Success");
      })
      .catch((error) => {
        dialogRef.close();
        // console.log(error);
        this.toastService.error(error, "Error");
      });
  }

  navigateToDashboard(user: any) {
    this.isAuthenticated = true;

    this.uid = user.uid;

    this.logsService.fetchLast5Logs(this.uid);

    this.getCurrentuser(true);
  }
}

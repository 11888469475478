import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {LogBean} from '../beans/LogBean';
import {LocalConstants} from '../beans/LocalConstants';
import {ToastrService} from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class LogsService {

    logsArray: Array<LogBean> = [];
    fetching = true;
    selectedResult;
    lastDocument;

    constructor(private db: AngularFirestore, public toastrService: ToastrService,) {

    }

    public fetchLast5Logs(uid: string) {

        // if (this.fetching) {
        //     return;
        // }
        // console.log('Fetching Logs');
        this.fetching = true;
        this.db.collection(LocalConstants.keysCollection).doc(uid).collection(LocalConstants.logsCollection, x => x.orderBy('lastDate', 'desc').limit(5))
            .get()
            .toPromise()
            .then((result) => {
                this.fetching = false;
                this.logsArray = [];
                // console.log(result);
                if (result.docs.length > 0) {
                    this.lastDocument = result.docs[result.docs.length - 1];

                    result.docs.forEach((doc) => {
                        this.logsArray.push(doc.data() as LogBean);
                    });
                }
            }).catch((error) => {
            // console.log('Error' + error);
        });

    }

    public fetchNext20Logs(uid: string) {

        if (!this.lastDocument) {
            this.toastrService.error('No Logs Available', 'Error');
            return;
        }
        if (this.fetching) {
            return;
        }

        this.fetching = true;
        this.db.collection(LocalConstants.keysCollection).doc(uid).collection(LocalConstants.logsCollection, x => x.orderBy('lastDate', 'desc').startAfter(this.lastDocument).limit(20))
            .get()
            .toPromise()
            .then((result) => {
                this.fetching = false;
                if (result.docs.length > 0) {
                    this.lastDocument = result.docs[result.docs.length - 1];

                    result.docs.forEach((doc) => {
                        this.logsArray.push(doc.data() as LogBean);
                    });
                } else {
                    this.toastrService.error('No More Logs Available', 'Error');
                }
            }).catch((error) => {
            // console.log('Error' + error);
        });

    }
}

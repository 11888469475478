import {Component, OnInit} from '@angular/core';



@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss']
})
export class LoadingDialogComponent implements OnInit {
  lottieConfig: any;

 

  constructor() {

    this.lottieConfig = {
      path: 'assets/loader.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };


  }

  
  ngOnInit() {
  }

}

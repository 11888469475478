export class KeySecret {
    public uid: string;
    public companyName: string;
    public email: string;
    public lastOrder: string;
    public name: string;
    public role: string;
    public phoneNumber: string;
    public phoneNumberCode: string;
    public phoneNumberInternational: string;
    public gstNumber: string;
    public link: string;

    public usages: number;

    public totalUsages: number;
    public paidUsages: number;
    public minLimit: number;

    public creationDate: {};
    public lastUsage: {};
    public lastAlert: {};


    public freeUsages: number;
    public lastOrderDate: Date;

}

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';

import {AppComponent} from './app.component';
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {AppRoutingModule} from './app.routing';
import {ComponentsModule} from './components/components.module';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {LoadingDialogComponent} from './dialogs/loading-dialog/loading-dialog.component';
import {ToastrModule} from 'ngx-toastr';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {AngularFirePerformanceModule} from '@angular/fire/performance';
import {AngularFireFunctionsModule,USE_EMULATOR} from '@angular/fire/functions';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule,MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';




@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        ComponentsModule,
        NgbModule,
        MatDialogModule,
        RouterModule,
        MatIconModule,
        MatDividerModule,
        MatButtonModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireFunctionsModule,
        AngularFireAnalyticsModule,
        AngularFirePerformanceModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        AppRoutingModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        LoadingDialogComponent,
        LandingPageComponent,
    ],
    providers: [
        ScreenTrackingService,
        UserTrackingService,
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'primary' },
        },
        // { provide: USE_EMULATOR, useValue: ['localhost', 9090] }
    ],
    entryComponents: [
        LoadingDialogComponent,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoginService} from '../../services/login.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    {path: '/app/dashboard', title: 'Dashboard', icon: 'ni-tv-2 text-primary', class: ''},
    {path: '/app/history', title: 'API Usage', icon: 'ni ni-calendar-grid-58', class: ''},
    {path: '/app/user-profile', title: 'My Profile', icon: 'ni-single-02 text-darker', class: ''},
    {path: '/app/buy-credits', title: 'Buy Credits', icon: 'ni-money-coins text-darker', class: ''},
    {path: '/app/orders', title: 'Orders', icon: 'ni-bag-17', class: ''},
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    public menuItems: any[];
    public isCollapsed = true;

    constructor(private router: Router, public loginService: LoginService) {
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.router.events.subscribe((event) => {
            this.isCollapsed = true;
        });
    }
}
